import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { paymentReceiverResolver } from './components/payment-receiver/payment-receiver.resolver';

export const routes: Routes = [
    {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'spenden',
                pathMatch: 'full'
            },
            {
                path: 'spenden',
                loadComponent: () => import('./components/donation/donation.component').then((x) => x.DonationComponent)
            },
            {
                path: 'tiere',
                loadComponent: () => import('./components/payment-receiver/payment-receiver.component').then((x) => x.PaymentReceiverComponent),
                resolve: { paymentReceivers: paymentReceiverResolver }
            },
            {
                path: 'umfragen',
                loadChildren: () => import('./components/survey/survey.routing').then((x) => x.SURVEY_ROUTES)
            }
        ]
    }
];
