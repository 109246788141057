import { CommonModule, registerLocaleData } from '@angular/common';
import * as de from '@angular/common/locales/de';
import { Component, inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule, RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { NavigationComponent } from './components/shared/navigation/navigation.component';
import { environment } from '../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { TopbarComponent } from './components/shared/topbar/topbar.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterOutlet, LoaderComponent, NavigationComponent, RouterModule, MatSidenavModule, TopbarComponent],
    providers: [{ provide: LOCALE_ID, useValue: 'de-DE' }]
})
export class AppComponent implements OnInit {
    isAuth0Loading$ = this.authService.isLoading$;
    private meta = inject(Meta);
    private title = inject(Title);

    constructor(private authService: AuthService) {
        registerLocaleData(de.default);
    }

    ngOnInit() {
        this.title.setTitle(environment.title);
    }

    menuItems: MenuItem[] = [
        {
            icon: 'menu-item-spenden',
            title: 'Spenden',
            path: '/spenden'
        },
        /*{
            icon: 'menu-item-spenden',
            title: 'Tiere',
            path: '/tiere'
        },*/
        /*{
            icon: 'menu-item-spenden',
            title: 'Projekte',
            path: '/projekte'
        },*/
        {
            icon: 'menu-item-umfragen',
            title: 'Umfragen',
            path: '/umfragen'
        }
    ];
    protected readonly environment = environment;
}

export interface MenuItem {
    icon: string;
    title: string;
    path: string;
}
