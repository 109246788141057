import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentReceiverService {

  constructor(private http: HttpClient) { }

    getAllPaymentReceivers(): Observable<PaymentReceiver[]>{
        return this.http.get<PaymentReceiver[]>(environment.backend.baseUrl + '/payment-receiver');
    }

    createPaymentReceiver(name: string, requiredAmount: number, receiverType: 'animal' = 'animal'): Observable<any>{
      return this.http.post<any>(environment.backend.baseUrl + '/payment-receiver', {
          name,
          receiverType,
          requiredAmount
      });
    }
}

export interface PaymentReceiver {
    id: string;
    name: string;
    requiredAmount: number;
    currentAmount: string;
    receiverType: string;
    active: boolean;
    createdAt: Date;
}
