import { Environment } from './environment.model';
import { prismicClient } from '../prismic';

export const environment: Environment = {
    production: false,
    name: 'DEV',
    prismic: {
        client: prismicClient
    },
    auth0: {
        domain: 'dev-ralphys-ranch.eu.auth0.com',
        clientId: 'aXmY2e448TkiAo1AXjKHyVaxnzOtqBFU'
    },
    auth0Audience: 'api-dev.ralphys-ranch.com',
    backend: {
        baseUrl: 'https://dev-dot-ralphys-ranch.ey.r.appspot.com/v1'
    },
    title: `[DEV] Ralphy's Ranch Backoffice`
};
